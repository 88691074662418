import {Spinner} from "react-bootstrap";

const styles = {
  spinnerContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100%',
    height: '100%',
  },
  spinner: {
    width: 100,
    height: 100,
    position: 'absolute',
    top: 462,
    left: 250,
  },
};

const Loader = props => {
  if (!props.show) {
    return (
      <></>
    );
  }
  return (
    <div style={styles.spinnerContainer}>
      <Spinner style={styles.spinner} animation="border" role="status" variant="light"/>
    </div>
  );
};

export default Loader;