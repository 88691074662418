import {Button, Col, Dropdown, DropdownButton, Jumbotron, Row} from "react-bootstrap";
import {useCallback, useEffect, useState} from "react";
import {BsArrowClockwise, BsCheck, BsFullscreen, BsGearFill} from "react-icons/bs";
import UserSelectionDialog from "./UserSelectionDialog";
import MODE from "../constants/mode";
import {modeToVerbWord} from "../utils/mode";
import axios from "axios";
import _ from "lodash";
import {useLocalStorage} from "@rehooks/local-storage";
import Loader from "./Loader";
import nl2br from "react-nl2br";

const styles = {
  jumbotron: {
    marginBottom: 0,
  },
  version: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.33)',
    float: 'left',
  },
  appName: {
    fontSize: '3.0rem',
  },
  menuContainer: {
    textAlign: 'right',
  },
  buttonsContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  btnCircleLg: {
    width: 120,
    height: 120,
    borderRadius: 60,
    fontSize: '2rem',
  },
  btnCircle: {
    width:  100,
    height: 100,
    borderRadius: 50,
    fontSize: '1.5rem',
  },
  spacer: {
    display: 'inline-block',
    height: 16,
    width: 16,
  },
};

const Home = props => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [mode, setMode] = useState(MODE.Attend);
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shops, setShops] = useLocalStorage('shops', []);
  const [selectedShopId, setSelectedShopId] = useLocalStorage('selectedShopId');
  const [users, setUsers] = useLocalStorage('users', []);
  const fetchUsers = useCallback(shopId => {
    setIsLoading(true);
    axios.get('/v1/users', {
      baseURL: process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_API_BASE_URI
        : process.env.REACT_APP_DEV_API_BASE_URI,
      params: {
        shop_id: shopId,
      },
    }).then(response => {
      setUsers(_.get(response, 'data.users', []));
    }).catch(() => {
      setUsers([]);
      props.setToast('システムエラー', nl2br('ユーザリストの取得に失敗しました\n店舗を再度選択してください'), 'danger');
    }).finally(() => {
      setIsLoading(false);
    });
  }, [props, setUsers]);
  const fetchShops = useCallback(() => {
    setIsLoading(true);
    axios.get('/v1/shops', {
      baseURL: process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_API_BASE_URI
        : process.env.REACT_APP_DEV_API_BASE_URI,
    }).then(response => {
      const data = _.get(response, 'data.shops', []);
      setShops(data);
      if (data.length > 0 && selectedShopId > 0
        && users instanceof Array && users.length === 0
      ) {
        fetchUsers(selectedShopId);
      } else {
        setIsLoading(false);
      }
    }).catch(() => {
      props.setToast('システムエラー', nl2br('店舗リストの取得に失敗しました\nリロードしてください'), 'danger');
      setIsLoading(false);
    });
  }, [props, fetchUsers, selectedShopId, users, setShops]);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);

    if (!isInitialized) {
      handleResizeWindow();
      if (!(shops instanceof Array) || shops.length === 0) {
        fetchShops();
      }
      setIsInitialized(true);
    }
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [isInitialized, fetchShops, shops]);

  const handleResizeWindow = () => {
    const el = document.querySelector('#jumbotron');
    if (el) {
      el.style.height = `${window.innerHeight}px`;
    }
  };
  const handleClickShop = shopId => {
    setSelectedShopId(shopId);
    fetchUsers(shopId);
  };
  const handleClickReloadShops = () => {
    fetchShops();
  };
  const handleClickAttend = () => {
    openUserSelectionDialog(MODE.Attend);
  };
  const handleClickLeave = () => {
    openUserSelectionDialog(MODE.Leave);
  };
  const handleClickBreak = () => {
    openUserSelectionDialog(MODE.Break);
  };
  const handleClickBack = () => {
    openUserSelectionDialog(MODE.Back);
  };

  const openUserSelectionDialog = mode => {
    setMode(mode);
    setShowUserSelection(true);
  };
  const closeUserSelectionDialog = () => {
    setShowUserSelection(false);
  };

  const renderMenu = () => {
    if (props.isFullscreen) {
      return '';
    }

    return (
      <div style={styles.menuContainer}>
        <DropdownButton variant="outline-secondary" menuAlign="right" title={<BsGearFill/>} id="dropdown">
          <Dropdown.Item className="mr-1" as="button" onClick={props.toggleFullscreen}>
            <BsFullscreen/> 全画面表示
          </Dropdown.Item>
          <Dropdown.Divider/>
          {shops.map((s, i) => {
            return (
              <Dropdown.Item key={i} onClick={() => {
                handleClickShop(s.id);
              }}>
                {s.id === selectedShopId ? (<BsCheck/>) : (<span style={styles.spacer}/>)} {s.name}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Divider/>
          <Dropdown.Item className="mr-1" as="button" onClick={() => {
            handleClickReloadShops();
          }}>
            <BsArrowClockwise/> 店舗リスト更新
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  };

  return (
    <>
      <Jumbotron id="jumbotron" style={styles.jumbotron}>
        <div style={styles.version}>ver.{process.env.REACT_APP_VERSION}</div>
        {renderMenu()}

        <div style={styles.buttonsContainer}>
          <h1 className="text-center">
            <img src={process.env.PUBLIC_URL + 'logo.png'} alt="Logo"/>
          </h1>
          <Row className="mt-5">
            <Col className="text-right">
              <Button variant="primary" style={styles.btnCircleLg} onClick={handleClickAttend}>
                {modeToVerbWord(MODE.Attend)}
              </Button>
            </Col>
            <Col>
              <Button variant="warning" style={styles.btnCircleLg} onClick={handleClickLeave}>
                {modeToVerbWord(MODE.Leave)}
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-right">
              <Button variant="success" style={styles.btnCircle} onClick={handleClickBreak}>
                {modeToVerbWord(MODE.Break)}
              </Button>
            </Col>
            <Col>
              <Button variant="info" style={styles.btnCircle} onClick={handleClickBack}>
                {modeToVerbWord(MODE.Back)}
              </Button>
            </Col>
          </Row>
        </div>
      </Jumbotron>

      <UserSelectionDialog
        users={users}
        show={showUserSelection}
        closer={closeUserSelectionDialog}
        mode={mode}
        setAuthUser={props.setAuthUser}/>

      <Loader show={isLoading}/>
    </>
  );
};

export default Home;