import {Button, Col, Figure, Image, Modal, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {modeToVerb, modeToVerbText} from "../utils/mode";

const styles = {
  avatarContainer: {
    overflow: 'hidden',
    width: 82,
    height: 82,
    borderRadius: '50%',
    backgroundColor: '#999999',
  },
  avatar: {
    width: '100%',
  },
  noAvatar: {
    display: 'table',
    height: '100%',
    width: '100%',
  },
  noAvatarInner: {
    display: 'table-cell',
    height: '100%',
    width: '100%',
    verticalAlign: 'middle',
  },
};

const UserSelectionDialog = props => {
  const history = useHistory();

  const handleClose = () => {
    props.closer();
  };
  const handleClickUser = user => {
    props.setAuthUser(user);
    history.push(`/auth/${modeToVerb(props.mode)}`);
  };

  const generateDummyUsers = () => {
    if (!(props.users instanceof Array)) {
      return [];
    }
    return props.users.map(u => {
      return {
        username: u.username,
        name: u.name.replace(/\s/g, '').replace(/　/, ''),
        avatar: u.avatar,
      };
    });
  };

  const renderUserSelector = () => {
    const users = generateDummyUsers();

    const cols = users.map((u, i) => {
      const src = typeof u.avatar === 'string' && u.avatar.length > 0 ? `data:image/jpeg;base64,${u.avatar}` : '';
      return (
        <Col key={i} sm={3} className="text-center">
          <Figure onClick={() => handleClickUser(u)}>
            <div style={styles.avatarContainer}>
              {src.length > 0 ? (
                <Image alt="no photo" src={src} style={styles.avatar}/>
              ) : (
                <div style={styles.noAvatar}>
                  <p style={styles.noAvatarInner}>no photo</p>
                </div>
              )}
            </div>
            <Figure.Caption className="text-center">{u.name}</Figure.Caption>
          </Figure>
        </Col>
      );
    });
    return (
      <Row>{cols}</Row>
    );
  };

  return (
    <Modal show={props.show} onHide={handleClose} animation={false} backdrop="static" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>{modeToVerbText(props.mode)}ユーザを選択してください</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderUserSelector()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserSelectionDialog;