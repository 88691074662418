import {useHistory, useParams} from "react-router-dom";
import Webcam from "react-webcam";
import {Button, Col, Row} from "react-bootstrap";
import {IoCamera, IoCameraReverse} from "react-icons/io5";
import {BsBackspace, BsCheckCircle} from "react-icons/all";
import {verbToVerbWord, verbToGreeting} from "../utils/mode";
import {useMemo, useRef, useState} from "react";
import axios from "axios";
import _ from "lodash";
import nl2br from "react-nl2br";
import Loader from "./Loader";
import Moment from "moment";

const WebCamAuth = props => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const isTaken = useMemo(() => {
    return ('' + imgSrc).length > 0;
  }, [imgSrc]);
  const history = useHistory();
  const {verb} = useParams();
  const user = props.authUser;
  if (!user.hasOwnProperty('username')) {
    history.goBack();
  }

  const styles = {
    header: {
      position: 'absolute',
      top: 0,
      zIndex: 9999,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#ffffff',
      width: '100%',
      padding: 10,
      margin: 0,
    },
    btnBack: {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      width: 55,
      height: 40,
      float: 'left',
    },
    title: {},
    titleVerb: {
      fontSize: '1.2rem',
      marginRight: '1.0rem',
    },
    titleName: {
      fontSize: '1.5rem',
    },
    titleUsername: {
      marginLeft: '0.5rem',
      fontSize: '1.2rem',
    },
    btnBackLabel: {
      position: 'relative',
      left: '0.25rem',
      fontSize: '1.5rem',
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      zIndex: 9999,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: '#ffffff',
      width: '100%',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    btnRetake: {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      width: 110,
      height: 80,
    },
    btnTake: {
      margin: '10px 20px',
      borderRadius: '50%',
      width: 110,
      height: 110,
    },
    btnUse: {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      width: 110,
      height: 80,
    },
    btnLeftLabel: {
      fontSize: '3rem',
      position: 'relative',
      left: '0.5rem',
    },
    btnMainLabel: {
      fontSize: '4rem',
    },
    btnRightLabel: {
      fontSize: '3rem',
      position: 'relative',
      right: '0.5rem',
    },
    stream: {
      backgroundColor: '#000000',
    },
    photo: {
      width: 600,
      height: 1024,
    },
  };
  const videoConstraints = process.env.NODE_ENV === 'production'
    ? {
      facingMode: 'user',
      height: 300,
      width: 512,
    } : {
      facingMode: 'user',
      height: 512,
      width: 300,
    };

  const handleClickBack = () => {
    history.goBack();
  };
  const handleClickRetake = () => {
    setImgSrc('');
  };
  const handleClickTake = () => {
    const data = webcamRef.current.getScreenshot({width:300, height:512});
    setImgSrc(data);
  };
  const handleClickUse = () => {
    setShowLoader(true);

    axios.post(`/v1/users/punch/${verb}`, {
      shop_id: localStorage.getItem('selectedShopId'),
      username: user.username,
      data: imgSrc.replace(/^data:.*?,/, ''),
    }, {
      baseURL: process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_API_BASE_URI
        : process.env.REACT_APP_DEV_API_BASE_URI,
    }).then(response => {
      const punchedAt = Moment(_.get(response, 'data.punched_at')).format('HH:mm');
      props.setToast(verbToGreeting(verb), (
        <>{verbToVerbWord(verb)}認証 {punchedAt} 成功<br/>自動的に初期画面に戻ります</>
      ), 'success');

      new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 3000);
      }).then(() => {
        history.goBack();
      });
    }).catch(err => {
      const message = _.get(err, 'response.data.error', '認証に失敗しました\n再撮影してください');
      props.setToast('認証失敗', nl2br(message), 'danger');
      setImgSrc('');
      setShowLoader(false);
    });
  };

  return (
    <>
      <Row style={styles.header}>
        <Col sm={2} className="text-left p-0">
          <Button variant="danger" style={styles.btnBack} onClick={handleClickBack}>
            <BsBackspace style={styles.btnBackLabel}/>
          </Button>
        </Col>
        <Col sm={8} className="text-center">
          <div style={styles.title}>
            <span style={styles.titleVerb}>{verbToVerbWord(verb)}:</span>
            <span style={styles.titleName}>{user.name}</span>
            <span style={styles.titleUsername}>({user.username})</span>
          </div>
        </Col>
      </Row>
      <div style={styles.footer}>
        <Button variant="secondary" style={styles.btnRetake} disabled={!isTaken} onClick={handleClickRetake}>
          <IoCameraReverse style={styles.btnLeftLabel}/>
        </Button>
        <Button variant="primary" style={styles.btnTake} disabled={isTaken} onClick={handleClickTake}>
          <IoCamera style={styles.btnMainLabel}/>
        </Button>
        <Button variant="success" style={styles.btnUse} disabled={!isTaken} onClick={handleClickUse}>
          <BsCheckCircle style={styles.btnRightLabel}/>
        </Button>
      </div>
      {isTaken ? (<img alt="taken for auth" src={imgSrc} style={styles.photo}/>) : (
        <Webcam
          width={600}
          height={1024}
          audio={false}
          mirrored={true}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={styles.stream}
        />
      )}

      <Loader show={showLoader}/>
    </>
  );
};

export default WebCamAuth;