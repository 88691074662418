/**
 * mode: 1, 2, ...
 * key: Attend, Leave, ...
 * verb: attend, leave, ...
 * verb text: 出勤する, 退勤する, ...
 * verb word: 出勤, 退勤, ...
 */

import MODE from "../constants/mode";

export const modeToVerbText = mode => {
  switch (mode) {
    case MODE.Attend:
      return '出勤する';
    case MODE.Leave:
      return '退勤する';
    case MODE.Break:
      return '休憩に入る';
    case MODE.Back:
      return '休憩から戻る';
    default:
      return '';
  }
};

export const modeToVerbWord = mode => {
  switch (mode) {
    case MODE.Attend:
      return '出勤';
    case MODE.Leave:
      return '退勤';
    case MODE.Break:
      return '休憩';
    case MODE.Back:
      return '復帰';
    default:
      return '';
  }
};

export const modeToGreeting = mode => {
  switch (mode) {
    case MODE.Attend:
      return 'おはようございます';
    case MODE.Leave:
      return 'お疲れ様でした';
    case MODE.Break:
      return 'いってらっしゃい';
    case MODE.Back:
      return 'おかえりなさい';
    default:
      return '';
  }
};

export const modeToVerb = mode => Object.keys(MODE).find(k => MODE[k] === mode).toLowerCase();

export const verbToVerbWord = verb => modeToVerbWord(verbToMode(verb));

export const verbToGreeting = verb => modeToGreeting(verbToMode(verb));

export const verbToKey = verb => verb.toLowerCase().replace(/\b([a-z])/g, c => c.toUpperCase()).replace(/\s/g, '');

export const verbToMode = verb => {
  const key = verbToKey(verb);
  return MODE.hasOwnProperty(key) ? MODE[key] : 0;
};