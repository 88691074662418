import 'bootstrap/dist/css/bootstrap.min.css';
import {hot} from 'react-hot-loader/root';
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import {Alert, Container, Toast} from "react-bootstrap";
import Home from './components/Home';
import WebCamAuth from "./components/WebCamAuth";
import {useEffect, useState} from "react";

const App = () => {
  const styles = {
    container: {
      maxWidth: 'none',
      margin: 0,
      padding: 0,
    },
    toastContainer: {
      zIndex: 20000,
      position: 'absolute',
      top: 10,
      right: 10,
    },
  };

  const [isInitialized, setIsInitialized] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [authUser, setAuthUser] = useState({});
  const [toastState, setToastState] = useState({
    title: '',
    body: '',
    variant: 'info',
    show: false,
  });

  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen().catch(err => {
        console.log(err);
      });
    } else {
      document.body.requestFullscreen({navigationUI:'hide'}).catch(err => {
        console.log(err);
      });
    }
  };
  const handleFullscreenChange = () => {
    setIsFullscreen(document.fullscreenElement !== null);
  };
  const setToast = (title, body, variant) => {
    setToastState({
      title: title,
      body: body,
      variant: variant,
      show: true,
    });
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    setIsInitialized(true);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isInitialized]);

  return (
    <BrowserRouter>
      <Container style={styles.container}>
        <div style={styles.toastContainer}>
          <Toast animation={false} autohide={true} delay={3000} onClose={() => {
            setToastState({
              ...toastState,
              show: false,
            });
          }} show={toastState.show}>
            <Alert variant={toastState.variant} className="mb-0">
              <Alert.Heading>{toastState.title}</Alert.Heading>
              <p className="mb-0">{toastState.body}</p>
            </Alert>
          </Toast>
        </div>

        <Switch>
          <Route path="/auth/:verb">
            <WebCamAuth authUser={authUser} setToast={setToast}/>
          </Route>
          <Route path="/">
            <Home
              toggleFullscreen={toggleFullscreen}
              isFullscreen={isFullscreen}
              setAuthUser={setAuthUser}
              setToast={setToast}/>
          </Route>
        </Switch>
      </Container>
    </BrowserRouter>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
